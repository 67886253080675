import api from "@/services/auctions.js";
import buy from "@/services/buy.js";
export default {
  namespaced: true,
  state() {
    return {
      listOptions:[],
      listAuction: [],
      pagination: {
        page: 1,
        page_size: 10,
      },
    };
  },
  mutations: {},
  getters: {},
  actions: {
    list({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        api
          .list()
          .then((res) => {
            state.listAuction = res.data.data.rows;
            dispatch("handleServiceException", { e: res }, { root: true });
            resolve(res);
          })
          .catch((err) => {
            dispatch("handleServiceException", { e: err }, { root: true });
            reject(err);
          });
      });
    },
  
    addCar({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        buy
          .addCar(payload)
          .then((res) => {
            dispatch("handleServiceException", { e: res }, { root: true });
            resolve(res);
          })
          .catch((err) => {
            dispatch("handleServiceException", { e: err }, { root: true });
            reject(err);
          });
      });
    },
    cancelOne({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        buy
          .cancelOne(payload)
          .then((res) => {
            dispatch("handleServiceException", { e: res }, { root: true });
            resolve(res);
          })
          .catch((err) => {
            dispatch("handleServiceException", { e: err }, { root: true });
            reject(err);
          });
      });
    },
  },
};
