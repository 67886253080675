import Vue from "vue";
const _ = require("lodash");
const subStringDate = (value) => {
  if (_.isEmpty(value)) {
    return "invalid date";
  }
  return new Date(value).toLocaleTimeString("en", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });
};


const copyToClipboard = (
  value,
  text,
  successfully = () => null,
  failure = () => null
) => {
  // window.alert("copied")

  Vue.toasted.show(`<span class="text">${text}</span>`, {
    position: "bottom-right",
    duration: 5000,
    type: "toast",
  });
  var domin = process.env.VUE_APP_Api// window.location.protocol + "//" + window.location.hostname;
  console.log("clipboard:" + domin + value)
  const clipboard = navigator.clipboard;
  if (clipboard !== undefined && clipboard !== "undefined") {
    navigator.clipboard.writeText(domin + value).then(successfully, failure);
  } else if (document.execCommand) {
    const el = document.createElement("input");
    el.value = value;
    document.body.append(el);

    el.select();
    el.setSelectionRange(0, value.length);

    if (document.execCommand("copy")) {
      successfully();
    }

    el.remove();
  } else {
    failure();
  }
};

export default { subStringDate, copyToClipboard };
