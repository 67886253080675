import Vue from "vue";
import VueI18n from "vue-i18n";
import VueCookies from "vue-cookies";
import en from "vuetify/lib/locale/en";
import de from "vuetify/lib/locale/de";
import fr from "vuetify/lib/locale/fr";

Vue.use(VueI18n);
var language = window.navigator.userLanguage || window.navigator.language;
var shortLang = language;
if (shortLang.indexOf("-") !== -1) shortLang = shortLang.split("-")[0];

if (shortLang.indexOf("_") !== -1) shortLang = shortLang.split("_")[0];

const messages = {
  en: {
    ...require("@/locales/en.json"),
    $vuetify: en,
  },
  de: {
    ...require("@/locales/de.json"),
    $vuetify: de,
  },
  fr: {
    ...require("@/locales/fr.json"),
    $vuetify: fr,
  },
};
if (VueCookies.get("lang") != null) shortLang = VueCookies.get("lang");
else shortLang = shortLang;
export default new VueI18n({
  locale: shortLang || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages,
});
