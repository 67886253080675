import Request from "../handleRequest/Request";
import { me, login, register, resetRequest, userAvatar } from "./api";
export default {
  /**
   * @des login to your account
   * @auther mosaab mhemeed
   */
  login(data) {
    return Request.post(login, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  Me() {
    return Request.get(me, "")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  meCollection() {
    return Request.get("me/collection", "")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  register(data) {
    return Request.post(register, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  updateMe(data) {
    return Request.post(me, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  uploadAvatar(data) {
    return Request.uploadFile(userAvatar, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },

  resetRequest(data) {
    return Request.post(resetRequest, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },

  restPassword(data) {
    return Request.post("auth/password-reset", data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  updatePassword(data) {
    return Request.put(me, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  updateLang(lang) { 
    return Request.get(`me/lang/${lang}`,"")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
};
