import auth from "../../services/auth";
import router from "@/router.js";
import i18n from "../../i18n.js";
import VueCookies from "vue-cookies";

export default {
  me({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      auth
        .Me()
        .then((res) => {
          dispatch("handleServiceException", { e: res }, { root: true });
          const user = res.data.data;
          localStorage.setItem("user", JSON.stringify(user));
          commit("userMe", user);
          resolve(res);
        })
        .catch((err) => {
          dispatch("handleServiceException", { e: err }, { root: true });
          reject(err);
        });
    });
  },
  login({ commit, dispatch }, userData) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      auth
        .login(userData)
        .then((res) => {
          dispatch("handleServiceException", { e: res }, { root: true });
          const token = res.data.data.auth_key;
          const user = res.data.data;
          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(user));
          VueCookies.set("lang", user.lang);
          commit("auth_success", token);
          commit("userMe", user);
          commit("authStatus", token);
          dispatch("auctions/list");
          router.replace("/app/home");
          resolve(res);
        })
        .catch((err) => {
          dispatch("handleServiceException", { e: err }, { root: true });
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          commit("auth_error", "");
          commit("logout", "");
          reject(err);
        });
    });
  },
  resetRequest({ commit, dispatch }, Data) {
    return new Promise((resolve, reject) => {
      auth
        .resetRequest(Data)
        .then((res) => {
          dispatch("handleServiceException", { e: res }, { root: true });
          resolve(res);
        })
        .catch((err) => {
          dispatch("handleServiceException", { e: err }, { root: true });
          reject(err);
        });
    });
  },
  /**
   * @author mosaab
   * @des this function for close session user
   * @param {*} param0
   */
  sessionExpired({ dispatch, commit }, { router }) {
    dispatch("clear", {}, { root: true });
    commit("logout");
    dispatch("logout");
    dispatch(
      "error",
      {
        showType: "toast",
        text: i18n.t("messages.sessionExpired"),
      },
      { root: true }
    );

    this.$router.push("/login");
  },

  updateMe({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      auth
        .updateMe(payload)
        .then((res) => {
          dispatch("handleServiceException", { e: res }, { root: true });
          resolve(res);
        })
        .catch((err) => {
          dispatch("handleServiceException", { e: err }, { root: true });
          reject(err);
        });
    });
  },
  uploadAvatar({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      auth
        .uploadAvatar(payload)
        .then((res) => {
          dispatch("handleServiceException", { e: res }, { root: true });
          resolve(res);
        })
        .catch((err) => {
          dispatch("handleServiceException", { e: err }, { root: true });
          reject(err);
        });
    });
  },
  updateLanguage({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      auth
        .updateLang(payload)
        .then((res) => {
          dispatch("handleServiceException", { e: res }, { root: true });
          resolve(res);
        })
        .catch((err) => {
          dispatch("handleServiceException", { e: err }, { root: true });
          reject(err);
        });
    });
  },
  register({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      auth
        .register(payload)
        .then((res) => {
          dispatch("handleServiceException", { e: res }, { root: true });
          const token = res.data.data.auth_key;
          const user = res.data.data;
          console.log("token",token)
          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(user));
          commit("auth_success", token);
          commit("userMe", user);
          commit("authStatus", token);
          router.replace("/app/home");
          resolve(res);
        })
        .catch((err) => {
          dispatch("handleServiceException", { e: err }, { root: true });
          reject(err);
        });
    });
  },
  restPassword({ dispatch, commit }, { key, password, router }) {
    dispatch("clear", {}, { root: true });
    commit("startRequest");

    auth
      .restPassword({ key, password })
      .then((response) => {
        commit("passwordResetSuccess", {});
        dispatch("handleServiceException", { e: response }, { root: true });
        // dispatch(
        //   "success",
        //   { showType: "toast", text: response.message },
        //   { root: true }
        // );
      })
      .catch((e) => {
        dispatch("handleServiceException", { e: e }, { root: true });

        commit("passwordResetFailure");

        // dispatch(
        //   "handleServiceException",
        //   { e, router },
        //   { root: true }
        // );
      });
  },
  handleAuthMessageKey({ dispatch }, { messageKey }) {
    switch (messageKey) {
      case "registerConfirmSuccess":
        dispatch(
          "success",
          {
            showType: "toast",
            title: "Success",
            text: i18n.t("messages.verified"),
          },
          { root: true }
        );
        break;
      case "registerConfirmFailed":
        dispatch(
          "error",
          {
            showType: "toast",
            title: "Failed",
            text: i18n.t("messages.unfortunately"),
          },
          { root: true }
        );
        break;
      case "registerConfirmValidationError":
        dispatch(
          "error",
          {
            showType: "toast",
            title: "Failed",
            text: i18n.t("messages.tokenFaild"),
          },
          { root: true }
        );
        break;
      default:
        break;
    }
  },
  logout({ commit }) {
    commit("logout");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    router.replace("/");
    window.location.reload();
  },
};
