import Request from "../handleRequest/Request";
const bid="api/buy/bid" 
export default {
  list() {
    return Request.get(bid, "")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  userList() {
    return Request.get(bid, "")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  addOne(data) {
    return Request.post(bid, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
};
