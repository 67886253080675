import api from "@/services/bid.js";
export default {
  namespaced: true,
  state() {
    return {
      listBID: [],
      pagination: {
        page: 1,
        page_size: 10,
      },
    };
  },
  mutations: {},
  getters: {},
  actions: {
    addOne({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        api
          .addOne(payload)
          .then((res) => {
            dispatch("handleServiceException", { e: res }, { root: true });
            resolve(res);
          })
          .catch((err) => {
            dispatch("handleServiceException", { e: err }, { root: true });
            reject(err);
          });
      });
    },
  },
};
