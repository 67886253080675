import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import "./plugins/base";
import GlobalFunction from "./helper/globalFunctions.js";
Vue.prototype.$subStringDate = GlobalFunction.subStringDate;
Vue.prototype.$copyToClipboard = GlobalFunction.copyToClipboard;
Vue.prototype.$VUE_APP_url_files = process.env.VUE_APP_url_files
import Toasted from "vue-toasted";
Vue.use(Toasted);

import VueCookies from "vue-cookies";
Vue.use(VueCookies);
// set default config
Vue.$cookies.config("7d");
// set global cookie
Vue.$cookies.set("ti18", "en");

import VueSplash from "vue-splash";
Vue.use(VueSplash);

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

Vue.config.productionTip = false; //run as production

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (store.state.user.isAuthenticated) {
      next();
    } else {
      next("/auth/login");
    }
  } else {
    next();
  }
});

axios.defaults.baseURL = process.env.VUE_APP_Api;
Vue.use(VueSweetalert2);
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
