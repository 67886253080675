// https://vuex.vuejs.org/en/mutations.html
import axios from "axios";
export default {
  auth_request(state) {
    // state.authStatus = false;
  },
  auth_success(state,  token ) {
    state.authStatus = true;
    state.token = token; 
    // axios.defaults.headers.common.Authorization =token
    state.isAuthenticated = true;
  },
  authStatus(state) {
    state.authStatus = true;
  },
  auth_error(state) {
    state.authStatus = false;
  },
  logout(state) {
    state.authStatus = false;
    state.token = "";
    state.userMe = "";
    state.isAuthenticated = false;
    axios.defaults.headers.common.Authorization = null;
  },
  userMe(state, payload) {
    state.userMe = payload;
  },
};
