import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "*",
  //   redirect: "/",
  // },
  {
    path: "/",
    redirect: "/app/home",
  },
  {
    path: "/auth/register",
    component: () => import("@/views/auth/Register.vue"),
    meta: {},
  },
  {
    path: "/auth/reset-password",
    component: () => import("@/views/auth/resetPassword.vue"),
    meta: {},
  },
  {
    path: "/auth/password-reset-box",
    component: () => import("@/views/auth/password-reset-from.vue"),
    meta: {},
  },
  {
    path: "/auth/login",
    component: () => import("@/views/auth/login.vue"),
    meta: {},
  },
  {
    path: "/404",
    component: () => import("@/views/notFoundPage.vue"),
    meta: {},
  },

  {
    path: "/app/",
    redirect: "/app/home",
  },
  {
    path: "/app",
    component: () => import("@/views/Index.vue"),
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/views/app/index.vue"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "buy/",
        name: "buy",
        component: () => import("@/views/app/buy/index.vue"),
        meta: {
          requireAuth: true,
        },
        children: [
          {
            path: "all",
            name: "buy",
            component: () => import("@/views/app/buy/all.vue"),
            meta: {
              requireAuth: true,
            },
          },
          {
            path: "purchased",
            name: "buy",
            component: () => import("@/views/app/buy/purchased.vue"),
            meta: {
              requireAuth: true,
            },
          },
          {
            path: "lost",
            name: "buy",
            component: () => import("@/views/app/buy/lost.vue"),
            meta: {
              requireAuth: true,
            },
          },
          {
            path: "losing",
            name: "buy",
            component: () => import("@/views/app/buy/losing.vue"),
            meta: {
              requireAuth: true,
            },
          },
          {
            path: "winning",
            name: "buy",
            component: () => import("@/views/app/buy/winning.vue"),
            meta: {
              requireAuth: true,
            },
          },
        ],
      },
      {
        path: "pending/",
        name: "pending",
        component: () => import("@/views/app/pending/index.vue"),
        meta: {
          requireAuth: true,
        },
        children: [
          {
            path: "inIfbid",
            name: "pending",
            component: () => import("@/views/app/pending/inIfbid.vue"),
            meta: {
              requireAuth: true,
            },
          },
        ],
      },
      {
        path: "sell/",
        name: "sell",
        component: () => import("@/views/app/sell/index.vue"),
        meta: {
          requireAuth: true,
        },
        children: [
          {
            path: "all",
            name: "sell",
            component: () => import("@/views/app/sell/all.vue"),
            meta: {
              requireAuth: true,
            },
          },
          {
            path: "noBidYet",
            name: "sell",
            component: () => import("@/views/app/sell/noBidYet.vue"),
            meta: {
              requireAuth: true,
            },
          },
          {
            path: "sold",
            name: "sell",
            component: () => import("@/views/app/sell/sold.vue"),
            meta: {
              requireAuth: true,
            },
          },
          {
            path: "withBid",
            name: "sell",
            component: () => import("@/views/app/sell/withBid.vue"),
            meta: {
              requireAuth: true,
            },
          },
          {
            path: "noSell",
            name: "sell",
            component: () => import("@/views/app/sell/noSell.vue"),
            meta: {
              requireAuth: true,
            },
          },
        ],
      },
      {
        path: "mycars/",
        name: "mycars",
        component: () => import("@/views/app/mycars/index.vue"),
        meta: {
          requireAuth: true,
        },
        children: [
          {
            path: "all",
            name: "mycars",
            component: () => import("@/views/app/mycars/all.vue"),
            meta: {
              requireAuth: true,
            },
          },
          {
            path: "approved",
            name: "mycars",
            component: () => import("@/views/app/mycars/approved.vue"),
            meta: {
              requireAuth: true,
            },
          },
          {
            path: "pending",
            name: "mycars",
            component: () => import("@/views/app/mycars/pending.vue"),
            meta: {
              requireAuth: true,
            },
          },
        ],
      },
      {
        path: "profile",
        name: "profile",
        component: () => import("@/views/app/profile/index.vue"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "publish",
        name: "publish",
        component: () => import("@/views/app/publish/index.vue"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "publish/complete",
        name: "completed",
        component: () => import("@/views/app/publish/completed.vue"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "publish/edite",
        name: "edite",
        component: () => import("@/views/app/publish/edite/index.vue"),
        meta: {
          requireAuth: true,
        },
      },
      // // ---------------------------------------------------------
      // {
      //   path: "allauction",
      //   name: "all auction",
      //   component: () => import("@/views/app/private/allauctions.vue"),
      //   meta: {
      //     requireAuth: true,
      //   },
      // },
      // {
      //   path: "myauctions",
      //   name: "my auction",
      //   component: () => import("@/views/app/private/myauctions.vue"),
      //   meta: {
      //     requireAuth: true,
      //   },
      // },
      // {
      //   path: "allcars",
      //   name: "all cars",
      //   component: () => import("@/views/app/private/allcars.vue"),
      //   meta: {
      //     requireAuth: true,
      //   },
      // },
      // {
      //   path: "my/cars",
      //   name: "my cars",
      //   component: () => import("@/views/app/private/mycars.vue"),
      //   meta: {
      //     requireAuth: true,
      //   },
      // },
      // {
      //   path: "notmycars",
      //   name: "not my cars",
      //   component: () => import("@/views/app/private/notMyCars.vue"),
      //   meta: {
      //     requireAuth: true,
      //   },
      // },
      // {
      //   path: "notMyAuction",
      //   name: "not My Auction",
      //   component: () => import("@/views/app/private/notMyAuction.vue"),
      //   meta: {
      //     requireAuth: true,
      //   },
      // },
      // {
      //   path: "myAuctionBid",
      //   name: "my Auction Bid",
      //   component: () => import("@/views/app/private/myAuctionBid.vue"),
      //   meta: {
      //     requireAuth: true,
      //   },
      // },
      // {
      //   path: "notMyAuctionBid",
      //   name: "not My Auction Bid",
      //   component: () => import("@/views/app/private/notMyAuctionBid.vue"),
      //   meta: {
      //     requireAuth: true,
      //   },
      // },
      // {
      //   path: "myBidAuction",
      //   name: "my Bid Auction",
      //   component: () => import("@/views/app/private/myBidAuction.vue"),
      //   meta: {
      //     requireAuth: true,
      //   },
      // },
      // {
      //   path: "myRunningAuction",
      //   name: "my Running Auction",
      //   component: () => import("@/views/app/private/myRunningAuction.vue"),
      //   meta: {
      //     requireAuth: true,
      //   },
      // },
      // {
      //   path: "notMyRunningAuction",
      //   name: "not my Running Auction",
      //   component: () => import("@/views/app/private/notMyRunningAuction.vue"),
      //   meta: {
      //     requireAuth: true,
      //   },
      // },
      // {
      //   path: "noBidYet",
      //   name: "Running Auction no Bid Yet",
      //   component: () => import("@/views/app/private/noBidYet.vue"),
      //   meta: {
      //     requireAuth: true,
      //   },
      // },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
