import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
export default {
  namespaced: true,
  state() {
    return {
      authStatus: localStorage.getItem("token") ? true : false,
      userMe: JSON.parse(localStorage.getItem("user")) || "",
      isAuthenticated: localStorage.getItem("token") ? true : false,
      token: localStorage.getItem("token") || "",
    };
  },
  getters,
  mutations,
  actions,
};
