import Request from "../handleRequest/Request";
const auctionList = "api/auction"; //post
export default {
  list() {
    return Request.get(auctionList, "")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
};
