//auth users
export const login = "auth/login"; //post
export const me = "me"; //get & post & put
export const userAvatar = "me/avatar"; //post
export const logout = "/auth/logout"; //post
export const register = "auth/register"; //post
export const resetRequest = "auth/password-reset-request"; ///post


//auction

export const postOne="api/auction/user" //get 
// export const userList="api/auction/user" //get 

 