import Request from "../handleRequest/Request";
const toQueryStrings = (params) => {
  return (
    "?" +
    Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&")
  );
};
export default {
  listOptions(router) {
    return Request.get("api/" + router + "/filtersArray", "")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },

  serchingCount(router, query) {
    var url = "api/" + router + "/serch/count" + toQueryStrings(query);
    return Request.get(url, "")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
};
