import Vue from "vue";
import Vuex from "vuex";
import alertStore from "./alert/alert";
import handleException from "./handleRequest/handleException";
import user from "./store/user";
import auctions from "./store/auctions";
import BID from "./store/BID";
import cars from "./store/cars";
import api from "@/services/settings.js";
import localApp from "./store/localApp.js";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    darkMode: false,
    barColor: "white",
    listOptions: { model: [], make: [] },
  },
  getters: {
    darkMode(state) {
      return state.darkMode;
    },
  },
  mutations: {
    changeDarkMode(state, payload) {
      state.darkMode = payload;
      $cookies.set("darkMode", payload);
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
  },
  actions: {
    changeDarkMode({ commit }, payload) {
      commit("changeDarkMode", payload);
    },
    loadSetting({ dispatch, state }, router) {
      return new Promise((resolve, reject) => {
        api
          .listOptions(router)
          .then((res) => { 
            state.listOptions = res.data.data;
            resolve(res);
          })
          .catch((err) => {
            dispatch("handleServiceException", { e: err }, { root: true });
            reject(err);
          });
      });
    },
  },
  modules: {
    namespace: true,
    handleException: handleException,
    alertStore: alertStore,
    user: user,
    auctions: auctions,
    BID: BID,
    cars: cars,
    localApp: localApp,
  },
});
