import api from "@/services/cars.js";
export default {
  namespaced: true,
  state() {
    return {
      loading: false,
      pagination: {
        page: 1,
        page_size: 10,
      },
    };
  },
  mutations: {},
  getters: {},
  actions: {
    addOne({ state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        state.loading = true
        api
          .addOne(payload)
          .then((res) => {
            state.loading = false
            dispatch("handleServiceException", { e: res }, { root: true });
            resolve(res);
          })
          .catch((err) => {
            state.loading = false
            dispatch("handleServiceException", { e: err }, { root: true });
            reject(err);
          });
      });
    },
    updateOne({ dispatch }, payload) {
      console.log(payload);
      return new Promise((resolve, reject) => {
        api
          .updateOne(payload)
          .then((res) => {
            dispatch("handleServiceException", { e: res }, { root: true });
            resolve(res);
          })
          .catch((err) => {
            dispatch("handleServiceException", { e: err }, { root: true });
            reject(err);
          });
      });
    },

    deleteOne({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        api
          .deleteOne(payload)
          .then((res) => {
            dispatch("handleServiceException", { e: res }, { root: true });
            resolve(res);
          })
          .catch((err) => {
            dispatch("handleServiceException", { e: err }, { root: true });
            reject(err);
          });
      });
    },
  },
};
