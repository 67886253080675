<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>
<style lang="scss">
.v-application--wrap {
  background-color: #eeeeee !important;
}
</style>
