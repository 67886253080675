const serverIO = process.env.VUE_APP_IO;
const { io } = require("socket.io-client");
import VueCookies from "vue-cookies";
// import i18n from "../../i18n.js";
import router from "@/router.js";
export default {
  namespaced: true,
  state() {
    return {
      socket: null,
      loading: true,
      uploadingFiles: false,
      countUploadedFile: 0,
      connected: false,
      finished: false,
      List: [],
    };
  },
  mutations: {
    set_List(state, data) {
      state.List = [];
      state.List = data;
    },
    clearStreem(state) {
      state.uploadingFiles = false
      state.finished = false
      state.countUploadedFile = 0
    },
    updateStatusItem(state, data) {
      try {
        var foundIndex = state.List.findIndex((x) => x.id == data.id);
        state.List[foundIndex].status = data.status;
        // state.List[foundIndex].bid = data.bid;
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    connected(state) {
      return state.connected;
    },
  },
  actions: {
    pushData({ commit }, payload) {
      commit("set_List", payload);
    },
    clearStreem({ commit }) {
      commit("clearStreem");
    },
    uploadFile({ state }, payload) {
      state.uploadingFiles = true
      state.socket.emit("upload", payload, (status) => {
        if (status == true) {
          state.uploadingFiles = false
          state.finished = true
        }

        state.countUploadedFile = state.countUploadedFile + 1
        console.log(status);
      });
    },
    initSocket({ state, dispatch, commit }) {
      //https://api2.pexcode.com/
      state.socket = io("https://api2.pexcode.com/", {
        secure: true, //ssl
        withCredentials: false,
        // path: "/",
        query: "version=1&authorization=" + localStorage.getItem("token"),
      });

      state.socket.on("connect", () => {
        state.connected = true;
        state.loading = false;
        VueCookies.set("uuid", state.socket.id);
      });
      state.socket.on("reload", () => {
        window.location.reload()
      });
      state.socket.on("disconnect", (e) => {
        state.connected = false;
        state.loading = true;
        console.log(e);
      });

      state.socket.on("status", (data) => {
        commit("updateStatusItem", data);
      });

      state.socket.on("sessionExpired", (data) => {
        console.log("sessionExpired");
        console.warn("The session was deleted !!!");
        dispatch("logout");
        dispatch(
          "error",
          {
            showType: "toast",
            text: "Session expired. Please login with your account.",
          },
          { root: true }
        );

        router.push("/login");
      });

      state.socket.on("error", (data) => {
        console.log("error", data);
      });
    },
  },
};
