export default {
  authorized: (state) => state.authStatus,
  authstatus: (state) => state.authStatus,
  userMe(state) {
    return state.userMe;
  },
  token(state) {
    return state.token;
  },
};
