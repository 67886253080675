import Request from "../handleRequest/Request";
export default {
  addCar(data) {
    return Request.post("api/sell/add", data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  cancelOne(id) {
    return Request.delete("api/sell/add" + "?vehicle_id=" + id, "")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
};
