import _ from "lodash";
export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    handleServiceException({ dispatch, commit }, { e, router = null }) {
      try {
        const { data, status } = e;
        let errorMessages = [];
        if (status === 404) {
          commit(
            "error",
            {
              showType: "toast",
              position: "bottom-right",
              title: "Error",
              text: data.message,
            },
            { root: true }
          );
        }
        if (status === 250) {
          commit(
            "error",
            {
              showType: "toast",
              position: "bottom-right",
              title: "Error",
              text: data.message,
            },
            { root: true }
          );
          commit(
            "setMessage",
            { type: "error", message:data.message},
            { root: true }
          );
        } else if (status === 200) {
          commit(
            "success",
            {
              showType: "toast",
              position: "bottom-right",
              title: "Success",
              text: data.message,
            },
            { root: true }
          );
        } else if (status === 422) {
          errorMessages = _.reduce(
            data.data,
            (errorMessages, tmpData) => {
              errorMessages.push(tmpData.msg);
              return errorMessages;
            },
            []
          );
          // console.log(errorMessages);
          commit(
            "setMessage",
            { type: "error", message: _.join(errorMessages, "\r\n") },
            { root: true }
          );
          commit(
            "error",
            {
              showType: "toast",
              position: "bottom-right",
              title: "Error",
              text: _.join(errorMessages, "\r\n"),
            },
            { root: true }
          );
        } else if (status === 401 || status === 403) {
          console.log(status);
          dispatch("user/sessionExpired", { router }, { root: true });
        }
      } catch (e) {
        dispatch(
          "error",
          {
            showType: "toast",
            position: "bottom-right",
            title: "Error",
            text: e.message,
          },
          { root: true }
        );
        console.error(e);
        throw new Error(e);
      }
    },
  },
};
