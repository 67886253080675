<template>
  <v-list-item
    v-if="checkRole(item.admin)"
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    :active-class="`primary ${!isDark ? 'white' : 'white'}--text`"
  >
    <v-list-item-icon
      v-if="text"
      class="v-list-item__icon--text"
      v-text="computedText"
    />

    <v-list-item-icon v-else-if="item.icon">
      <v-icon v-text="item.icon" />
    </v-list-item-icon>

    <v-list-item-content v-if="item.title || item.subtitle">
      <v-list-item-title v-text="item.title" />

      <!-- <v-list-item-subtitle v-text="item.subtitle" /> -->
    </v-list-item-content>
    <v-badge
      v-if="item.title == 'سجل الطلبات' && notDeliverdOrder.length > 0"
      overlap
      color="red"
      dot
    ></v-badge>
  </v-list-item>
</template>

<script>
import Themeable from "vuetify/lib/mixins/themeable";
import { mapActions, mapState } from "vuex";
export default {
  name: "Item",

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({
        admin: null,
        href: undefined,
        icon: undefined,
        subtitle: undefined,
        title: undefined,
        to: undefined,
      }),
    },
    text: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState("orders", ["orders", "loading"]),
    userMe() {
      return this.$store.getters["users/userMe"];
    },
    notDeliverdOrder() {
      return this.orders.filter((order) => {
        return (
          order.status != 2 &&
          new Date(order.created_at).getDay() == new Date().getDay() &&
          new Date(order.created_at).getMonth() == new Date().getMonth()
        );
      });
    },

    computedText() {
      if (!this.item || !this.item.title) return "";

      let text = "";

      this.item.title.split(" ").forEach((val) => {
        text += val.substring(0, 1);
      });

      return text;
    },
    href() {
      return this.item.href || (!this.item.to ? "#" : undefined);
    },
  },
  methods: {
    checkRole(role) {
      if (role === null) return true;
      else if (role == this.userMe.role) return true;
      else return false;
    },
  },
};
</script>
