import Request from "../handleRequest/Request";
const url = "api/sell/add";
const toQueryStrings = (params) => {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
};
export default {
  getall() {
    return Request.get("api/cars/all", "")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  getOne(id) {
    return Request.get("api/sell/cars/check/" + id, "")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  userList(query) {
    var ul = url + "?" + toQueryStrings(query);
    return Request.get(ul, "")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  checkVIN(vin) {
    var ul = "api/sell/cars/vin/" + vin;
    return Request.get(ul, "")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  addOne(data) {
    return Request.post("api/sell/cars/add", data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  updateOne(data) {
    return Request.patch("api/sell/cars/" + data.id, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  deleteOne(id) {
    return Request.delete("api/sell/cars/", id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
  // uploadFiles(id, data) {
  //   return Request.put("api/sell/cars/" + id, data)
  //     .then((res) => {
  //       return res;
  //     })
  //     .catch((err) => {
  //       throw err;
  //     });
  // },
  sellAuction(id) {
    console.log(id)
    return Request.get("api/sell/auction/" , id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
};
